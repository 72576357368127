










import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator';
// https://greensock.com/forums/topic/28599-gsap-imports-tree-shaking-reduce-bundle-size/
import { gsap, CSSPlugin } from 'gsap';

import { state } from './SiteReveal/SiteRevealState';

gsap.registerPlugin(CSSPlugin);

@Component
export default class AnimateText extends Vue {
  @Prop({ type: String, default: 'span' })
  tag!: string

  @Prop({ type: String, required: true })
  text!: string

  @Prop({ type: Number, default: 1 })
  duration!: number

  @Prop({ type: Number, default: 0 })
  delay!: number

  @Prop({ type: Number, default: 0.05 })
  stagger!: number

  @Prop({ type: Boolean, default: true })
  appear!: boolean

  get separatedWords () {
    return this.text.replace(
      /(\S+)/g,
      '<span class="animate-text_word-wrapper"><span class="animate-text_word">$&</span></span>',
    );
  }

  get readyToAnimate () {
    return state.siteIsReady && this.appear;
  }

  @Watch('readyToAnimate')
  animateWhenLoaded (readyToAnimate) {
    if (readyToAnimate)
      this.animate();
  }

  mounted () {
    if (this.readyToAnimate)
      this.animate();
  }

  animate () {
    const text = this.$refs.text as HTMLElement;
    if (!this.text)
      return;

    gsap.set(text, { opacity: 1 });
    gsap.fromTo(
      text.getElementsByClassName('animate-text_word'),
      { yPercent: 100 },
      { yPercent: 0, rotation: 0.01, duration: this.duration, delay: this.delay, stagger: this.stagger, ease: 'expo.out' },
    );
  }
}
