










import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { PageBlockMixin } from '@/modules/cms';
import { ScenkonstSpotlightBlockDataFragment } from '@/graphql/generated';
import { largeImageFragment } from '@/graphql/queries/image.fragments';

@Component({
  graphqlData: gql`
    fragment ScenkonstSpotlightBlockData on ScenkonstSpotlightBlock {
      id
      title
      subtitle
      linkText
      linkUrl
      poster: image {
        ...LargeImageData
      }
      videoLoopUrl
    }

    ${largeImageFragment}
  `,
})
export default class ScenkonstSpotlightBlock extends mixins(PageBlockMixin<ScenkonstSpotlightBlockDataFragment>()) {}
