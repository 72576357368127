


































import { Component, Vue, Prop } from 'nuxt-property-decorator';

import { IconPause, IconPlay } from '@/icons';
import type { LargeImageDataFragment } from '@/graphql/generated';

@Component({
  components: {
    IconPause,
    IconPlay,
  },
})
export default class InlineVideoLoop extends Vue {
  @Prop({ type: String, required: true })
  source!: string

  @Prop(Boolean)
  playToggle!: boolean

  @Prop(Object)
  posterImage?: LargeImageDataFragment

  isPlaying: boolean = false;

  togglePlayState () {
    const videoEl = this.$refs.video as HTMLVideoElement;

    if (videoEl) {
      this.isPlaying ? videoEl.pause() : videoEl.play();
      this.isPlaying = !this.isPlaying;
    }
  }

  get resolvedPosterImage () {
    return this.posterImage?.variant_16x9_1920_jpg.url;
  }
}
