






















import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class BlockSpotlight extends Vue {
  @Prop({ type: String, required: true })
  title!: string

  @Prop({ type: String, required: false })
  subtitle!: string

  @Prop({ type: String, required: false })
  linkPath!: string

  @Prop({ type: String, required: false })
  linkText!: string

  @Prop({ type: Object, required: false })
  posterImage!: object

  @Prop({ type: String, required: false })
  videoLoopUrl!: string
}
